define('vopay_app/services/datadog', ['exports', 'ember', 'vopay_app/config/environment', '@datadog/browser-logs', '@datadog/browser-rum'], function (exports, _ember, _vopay_appConfigEnvironment, _datadogBrowserLogs, _datadogBrowserRum) {
    exports['default'] = _ember['default'].Service.extend({
        isIinitialized: false,
        initialize: function initialize() {
            var datadog = _vopay_appConfigEnvironment['default'].datadog;

            _datadogBrowserLogs.datadogLogs.init({
                clientToken: datadog.clientToken,
                site: datadog.site,
                env: datadog.env,
                forwardErrorsToLogs: true,
                silentMultipleInit: true,
                service: datadog.service,

                sampleRate: 100
            });
            _datadogBrowserRum.datadogRum.init({
                applicationId: datadog.applicationId,
                clientToken: datadog.clientToken,
                site: datadog.site,
                env: datadog.env,
                service: datadog.service,
                trackResources: true,
                trackLongTasks: true,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100, // if not included, the default is 100
                silentMultipleInit: true,
                sampleRate: 100
            });
            _datadogBrowserRum.datadogRum.startSessionReplayRecording();
            this.set("isIinitialized", true);
        },
        setUser: function setUser(PortalUser, Key, Signature, AccountId, AccountName, JWT) {
            if (!this.get("isIinitialized")) {
                this.initialize();
            }
            _datadogBrowserLogs.datadogLogs.setUser({ AccountId: AccountId, Key: Key, Signature: Signature, PortalUser: PortalUser, AccountName: AccountName, JWT: JWT });
            _datadogBrowserRum.datadogRum.setUser({ AccountId: AccountId, Key: Key, Signature: Signature, PortalUser: PortalUser, AccountName: AccountName, JWT: JWT });
        },
        clearUser: function clearUser() {
            _datadogBrowserLogs.datadogLogs.clearUser();
            _datadogBrowserRum.datadogRum.clearUser();
        },
        getUser: function getUser() {
            return _datadogBrowserLogs.datadogLogs.getUser();
        }
    });
});